import PersonalizationCardImage from "./components/PersonalizationCardImage";
import PersonalizationInput from "./components/PersonalizationInput";
import PersonalizationTextarea from "./components/PersonalizationTextarea";

export default function PersonalizationWelcomeScreen({getError, surveySettingsFormData,setSurveySettingsFormData}) {

    return <div style={{
        display: "flex",
        flexDirection: "column",
        gap:'20px'
    }}>
        <PersonalizationCardImage src={'/assets/survey_personalization_screens/welcome_1.png'} alt={'Welcome Screen'} />

        <PersonalizationInput
            title={'Title'}
            value={surveySettingsFormData?.introSubtitle?.toUpperCase()}
            setValue={(value) => setSurveySettingsFormData({introSubtitle:value?.toUpperCase()})}
            errorMessage={getError('surveySettings', 'introSubtitle')}
        />


        <PersonalizationInput
            title={'Section Title'}
            value={surveySettingsFormData?.introHeadline}
            setValue={(value) => setSurveySettingsFormData({introHeadline:value})}
            errorMessage={getError('surveySettings', 'introHeadline')}
        />


        <PersonalizationTextarea
            title={'Description'}
            value={surveySettingsFormData?.introText}
            setValue={(value) => setSurveySettingsFormData({introText:value})}
            errorMessage={getError('surveySettings', 'introText')}
        />

        <PersonalizationInput
            title={'Video Link (Optional)'}
            value={surveySettingsFormData?.introVideo}
            setValue={(value) => setSurveySettingsFormData({introVideo:value})}
            errorMessage={getError('surveySettings', 'introVideo')}
        />
    </div>
}
